.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--background-color);
    width: 100%;
    margin: 0;
    padding: 0;
    height: 80px;
}

.menu-links a:hover {
    color: var(--accent-color); 
}

.nav-links a, .social-media-links a {
    margin-right: 15px;
    text-decoration: none;
    padding: 0 15px;
    font-size: 16px;
    line-height: 60px; /* Equal to navbar height for vertical centering */
}

.nav-links a:hover, .social-media-links a:hover, .icon:hover {
    color: var(--accent-color); /* Change to your preferred hover color */
}


.social-media-links i, .icon {
    font-size: 24px; /* Adjust icon size */
    color: var(--secondary-color);
}

.hamburger-icon {
    cursor: pointer;
    position: absolute; /* Ensure it's positioned relative to its nearest positioned ancestor */
    top: 20px;
    right: 20px; /* Adjusted to right */
    z-index: 100;
}

.hamburger-icon div {
    width: 30px;
    height: 3px;
    background-color:var(--primary-color);
    margin: 5px 0;
    transition: transform 0.3s ease, opacity 0.3s ease, background-color 0.3s ease; /* Adding background-color transition */ /* For smooth transition */
}

.hamburger-icon.open div {
    background-color: var(--secondary-color); /* Color when menu is open */
}





.hamburger-icon.open div:nth-child(1) {
    transform: rotate(45deg) translate(5px, 5px);
}

.hamburger-icon.open div:nth-child(2) {
    opacity: 0;
}

.hamburger-icon.open div:nth-child(3) {
    transform: rotate(-45deg) translate(7px, -6px);
}

.slide-out-menu {
    position: fixed;
    top: 0;
    left: -100%; /* Start off-screen */
    width: 100%;
    height: 100%;
    transition: left 0.3s ease;
    z-index: 50;
    padding: 20px;
    box-sizing: border-box;
    background-color: var(--primary-color);
}

.slide-out-menu.open {
    left: 0; /* Slide in */
}

.menu-links {
    padding-top: 60px;
    font-size: xx-large;
}

.menu-links a {
    display: block;
    color: var(--secondary-color);
    padding: 25px 150px;
}

.social-media-links {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 20px;
    width: 100%;
}

@media (max-width: 768px) {
    .slide-out-menu {
        width: 100%; /* Full width on smaller screens */
    }
}
