.main-content {
    height: 100vh; /* 100% of the viewport height */
    width: 100vw; /* 100% of the viewport width */
    display: flex;
    align-items: center;
    text-align: left;
    padding-left: 50px;
    /* Add other styles as needed */
}

.main-content h1 {
    font-size: 6.5rem;
    margin: 0;
    color: var(--primary-color);
    /* Additional styling */
}