.accordion {
    width: 1200px;
    margin: 40px auto;
    background-color: #f0f0f0;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    padding: 25px;
}

.accordion-item {
    border-bottom: 1px solid #ccc;
}

.accordion-title {
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: space-between;
    cursor: pointer;
    padding: 15px;
    color: var(--primary-color);
    font-size: x-large;
}

.title-content {
    display: flex;
    align-items: center;
    
}

.company-logo {
    height: 100px;
    width: 100px;
    object-fit: contain;
    margin-right: 10px;
}

.position-divider {
    height: 100%;
    width: 1px;
    background-color: #ccc;
    margin: 0 10px;
}

.accordion-indicator {
    margin-left: 10px;
}

.accordion-content {
    background-color: #f0f0f0;
    color: var(--primary-color);
    overflow: hidden;
    max-height: 0;
    opacity: 0;
    transition: max-height 0.5s ease-out, opacity 0.5s ease-out;
    font-weight: 300;
    margin-bottom: 10px;
}

.accordion-item.active .accordion-content {
    max-height: 1000px;
    opacity: 1;
}

@media (max-width: 600px) {
    .accordion {
        padding: 10px;
        margin: 20px 0;
    }

    .accordion-title {
        font-size: medium;
    }

    .company-logo {
        height: 20px;
        width: 20px;
    }
}
