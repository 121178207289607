@import url("https://use.typekit.net/wxb0mqi.css");
body {
  font-family: "new-order", sans-serif;
  font-weight: 400;
  font-style: normal;
  line-height: 1.6;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  background-color: var(--background-color);
}

h1, h2, h3 {
  margin-bottom: 1rem;
  font-weight: 500;
  font-style: bold;


}
 h4, h5, h6 {
  margin-bottom: 1rem;
  font-weight: 300;
  font-style: normal;
}




:root {
  --primary-color: #00111c;
  --secondary-color: #F7F7F7;
  --accent-color: #2E8B57;
  --text-color: #00111c;
  --background-color: #fffff;
}

body {
  background-color: var(--background-color);
  color: var(--accent-color);
}
.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px;
}

/* Flexbox example */
.flex-row {
  display: flex;
  justify-content: space-between;
}

@media (max-width: 768px) {
  .container {
      padding: 0 10px;
  }

  h1 {
      font-size: 2rem;
  }
}

a {
  color: var(--text-color);
  text-decoration: none;
}

.btn {
  background: var(--primary-color);
  color: #fff;
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
