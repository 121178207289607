.footer {
    display: flex;
    justify-content: space-evenly; /* Evenly spaces the columns */
    align-items: center; /* Centers columns vertically */
    padding: 20px;
    background-color:var(--accent-color);
    color: var(--secondary-color);
    min-height: 150px;
}

.footer-column {
    flex: 1; /* Each column takes equal width */
    text-align: center; /* Centers the content of each column */
    padding: 0 15px; 
    /* Additional styling as needed */
}

.footer-column a {
    display: block;
    color:var(--background-color);
    text-decoration: none;
    /* Additional styling as needed */
}

/* Styling for social media icons */
.footer-column i {
    font-size: 24px;
    color: white;
    /* Additional styling as needed */
}
