/* In ArchivePage.css */
.archive-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; /* This will center the child elements vertically */
    padding: 20px;
}

.archive-page h1 {
    text-align: center;
}

.article-box {
    width: 80%; 
    padding: 20px;
    display: flex;
    margin-bottom: 20px;
    background-color: var(--secondary-color);
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    overflow: hidden;
}

.article-image {
    width: 150px;
    height: 150px;
    object-fit: cover;
    border-radius: 10px;
}

.article-content {
    padding: 15px;
}

.article-content h2 {
    margin-top: 0;
    color: var(--primary-color);
}


