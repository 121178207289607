/* NewsletterSignUp.css */

.newsletter-signup {
    text-align: center;
    margin: 20px;
    padding: 20px;
    border-radius: 8px;
  }
  
  .newsletter-signup h2 {
    color: var(--accent-color);
    margin-bottom: 20px;
  }
  
  .newsletter-signup form {
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
  }
  
  .newsletter-signup input {
    padding: 10px;
    margin: 5px;
    border: 1px solid #ddd;
    border-radius: 4px;
    width: 30%;
  }
  
  .newsletter-signup button {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    background-color: var(--accent-color);
    color: white;
    cursor: pointer;
  }
  

  